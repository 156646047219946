import React from 'react'
import cx from 'classnames'
import { Trans, t } from '@lingui/macro'
import { Field } from 'components/common/Form'
import { withLocale } from 'components/common/Locale'
import { Select, ImageUpload, Checkbox, Typeahead } from 'components/common/Input'
import { validators } from '~/utils/validation'
import { yearOptions } from '~/utils/misc'
import { OptionalFieldWrapper, SplitFieldWrapper } from 'components/common/Form/Ui'
import { getOptions, getLabel } from './utils'
import withOptions from './withOptions'
import style from './fields.module.scss'

const Professional = ({ allOptions, i18n, onSubmit, defaultValues = {}, currentValues, disabled }) =>
  <>
    <Field
      defaultValue={ defaultValues.firstName }
      label={ i18n._(t`Nombre`) }
      name="firstName"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.lastName }
      label={ i18n._(t`Apellidos`) }
      name="lastName"
      type="text"
      disabled={ disabled }
      validate={ validators.required }
    />

    <OptionalFieldWrapper>
      <SplitFieldWrapper>
        <Field
          className={ style.phoneField }
          defaultValue={ defaultValues.phone }
          label={ i18n._(t`Teléfono de contacto`) }
          name="phone"
          type="text"
          disabled={ disabled }
        />
        <Field
          className={ style.extensionField }
          defaultValue={ defaultValues.extension }
          label={ i18n._(t`Extensión`) }
          name="extension"
          type="text"
          disabled={ disabled }
        />
      </SplitFieldWrapper>
    </OptionalFieldWrapper>

    <Field
      defaultValue={ defaultValues.workCountry }
      label={ i18n._(t`País donde ejerces`) }
      name="workCountry"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ...getOptions('countries', allOptions).map(([value, label]) => [label, label])
      ]}
    />

    <Field
      defaultValue={ defaultValues.workCity }
      label={ i18n._(t`Ciudad donde trabajas`) }
      name="workCity"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.profession }
      label={ i18n._(t`Profesión y especialidad`) }
      name="profession"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={ getOptions('professions', allOptions) }
    />

    <Field
      defaultValue={ defaultValues.registrationNumber }
      label={ i18n._(t`Número de colegiado`) }
      name="registrationNumber"
      type="text"
      validate={ currentValues.noRegistrationNumber ? undefined : validators.required }
      disabled={ currentValues.noRegistrationNumber || disabled }
    />

    <Field
      defaultValue={ defaultValues.noRegistrationNumber }
      name="noRegistrationNumber"
      component={ Checkbox }
      type="checkbox"
      selectionControlLabel={ <Trans>No tengo número de colegiado</Trans> }
      disabled={ disabled }
    />
  </>

export default withLocale(withOptions(Professional))