import { t } from '@lingui/macro'
import { flowRight, map, mapValues, negate, isEmpty, trim, uniq } from 'lodash'
import i18n from '../i18n'

export const makeValidateFn = ([ checkFn, message ]) =>
  value => checkFn(value) ? '' : message

export const all = rules =>
  value =>
    map(rules, makeValidateFn).reduce((accum, validateFn) => accum || validateFn(value), undefined)

const isEmail = (value = '') => /^\S+@\S+\.\S+/.test(value)
const isPhone = (value = '') => /^[0-9()-]+$/.test(value)
const isNonEmptyZero = value => value || value === 0

const isPasswordStrong = (value = '') =>
  value.length >= 8 &&
  value.toLowerCase() !== value &&
  /\d/.test(value)

const trimmedLowerCase = flowRight(trim, (str = '') => str.toLowerCase())

const hasUniqueItems = (values = []) => uniq(map(values, trimmedLowerCase)).length === values.length

export const rules = {
  required: [ isNonEmptyZero, i18n._(t`Campo requerido`) ],
  email: [ isEmail, i18n._(t`Introduce una dirección válida`)],
  phone: [ isPhone, i18n._(t`Introduce un teléfono válido`) ],
  strongPassword: [ isPasswordStrong, i18n._(
    t`La contraseña debe estar formada por un mínimo de 8 caracteres y debe contener mayúsculas, minúsculas y un número.`
  ) ],
  uniqueItems: [ hasUniqueItems, i18n._(t`Introduce valores diferentes entre sí`) ],
}

export const validators = {
  ...mapValues(rules, makeValidateFn),
}
