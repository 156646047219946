import React from 'react'
import { Link } from 'gatsby'
import { Trans, t } from '@lingui/macro'
import Button from 'components/common/Button'
import { withLocale } from 'components/common/Locale'
import { Title, Main, Actions, Message } from 'components/common/Form/Ui'
import routes from '~/routes'

export default withLocale(({ i18n, isProfessional }) => (
  <Main>
    <Title>
      <Trans>¡Ya tienes tu cuenta!</Trans>
    </Title>
    <Message>
      {
        isProfessional ?
          <Trans>
            Tu cuenta de usuario profesional debe ser validada por el equipo de Lemon.
            Al completar este proceso tendrás acceso a la plataforma.
          </Trans> :
          <Trans>
            Te hemos enviado un correo donde podrás completar tu registro.
          </Trans>
      }
    </Message>

    <Title>
      <strong>Bienvenido/a a lemon.</strong>
    </Title>
    <Actions>
      <Button to={ routes.home() } component={ Link } isSecondaryDark>
        <Trans>Ir al inicio</Trans>
      </Button>
      <Button to={ routes.login() } component={ Link } isPrimary>
        <Trans>Accede</Trans>
      </Button>
    </Actions>
  </Main>
))