import { memoize, get, flowRight } from 'lodash'
import { findNode } from '~/utils/content'

export const getNode = memoize((fieldName, allFieldOptions) =>
  findNode(allFieldOptions, ({ slug }) => slug === fieldName)
)

export const getOptions = flowRight(
  node => node && node.options ?
    node.options.map(option => ([ option.value, option.label ])) :
    [],
  getNode,
)

export const getLabel = flowRight(node => node.label, getNode)

export const getHint = flowRight(node => node.hint, getNode)

