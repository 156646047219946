import React, { forwardRef } from 'react'
import { Trans, t } from '@lingui/macro'
import { withLocale } from 'components/common/Locale'
import { Field } from 'components/common/Form'
import { Typeahead } from 'components/common/Input'
import { Pills, Select, TypeaheadGroup } from 'components/common/Input'
import { Help } from 'components/common/Form/Ui'
import { yearOptions } from '~/utils/misc'
import { all, rules, validators } from '~/utils/validation'
import { getOptions, getLabel } from './utils'
import withOptions from './withOptions'

const General = ({ allOptions, centers, i18n, onSubmit, currentValues, defaultValues = {}, defaultUserType, disabled, withInstitutionHelp, canEditPassword }) => (
  <>
    <Field
      defaultValue={ defaultValues.email }
      label={ i18n._(t`Correo electrónico`) }
      name="email"
      type="text"
      validate={ validators.email }
      disabled={ disabled }
    />

    {
      canEditPassword ?
        <Field
          defaultValue={ defaultValues.password }
          label={ i18n._(t`Contraseña`) }
          name="password"
          type="password"
          validate={ validators.strongPassword }
          disabled={ disabled }
        /> :
        null
    }

    {
      !defaultUserType ?
        <Field
          defaultValue={ defaultValues.type }
          label={ getLabel('userTypes', allOptions) }
          name="type"
          validate={ validators.required }
          component={ Select }
          disabled={ disabled }
          options={[
            ['', i18n._(t`Selecciona la opcion más apropiada`)],
            ...getOptions('userTypes', allOptions),
          ]}
        /> :
        null
    }

    <Field
      defaultValue={ defaultValues.birthday }
      label={ i18n._(t`Año de nacimiento`) }
      name="birthday"
      validate={ validators.required }
      component={ Select }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ...yearOptions(100),
      ]}
    />

    <Field
      defaultValue={ defaultValues.gender }
      label={ i18n._(t`Género`) }
      name="gender"
      validate={ validators.required }
      component={ Pills }
      disabled={ disabled }
      options={[
        ['M', i18n._(t`Hombre`)],
        ['F', i18n._(t`Mujer`)],
      ]}
    />

    <Field
      defaultValue={ defaultValues.postcode }
      label={ i18n._(t`Código Postal`) }
      name="postcode"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.centers }
      label={ getLabel('institutions', allOptions) }
      name="centers"
      component={ TypeaheadGroup }
      options={ getOptions('institutions', allOptions).map(([value, label]) => [label, label]) }
      addLabel={ i18n._(t`Añadir otro Hospital/Centro o Fundación`)}
      validate={ all([rules.required, rules.uniqueItems]) }
      disabled={ disabled }
      isOpen
    />

    {
      withInstitutionHelp ?
        <Help>
          <Trans>Escribe y añade los Hospitales, Centros y/o Fundaciones con las que te relaciones</Trans>
        </Help> :
        null
    }
  </>
)

export default withLocale(withOptions(General))