import React from 'react'
import { first } from 'lodash'
import { t } from '@lingui/macro'
import { Field } from 'components/common/Form'
import { withLocale } from 'components/common/Locale'
import { Select, RadioGroup, CheckboxGroup } from 'components/common/Input'
import { validators } from '~/utils/validation'
import { yearOptions } from '~/utils/misc'
import { getOptions, getLabel, getHint } from './utils'
import withOptions from './withOptions'

const Injured = ({ allOptions, i18n, onSubmit, defaultValues = {}, currentValues, disabled }) =>
  <>
    <Field
      defaultValue={ defaultValues.firstName }
      label={ i18n._(t`Nombre`) }
      name="firstName"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.lastName }
      label={ i18n._(t`Apellidos`) }
      name="lastName"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.phone }
      label={ i18n._(t`Teléfono de contacto`) }
      name="phone"
      type="text"
      validate={ validators.phone }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.injuryYear }
      label={ i18n._(t`Año de la lesión`) }
      name="injuryYear"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ...yearOptions(60),
      ]}
    />

    <Field
      defaultValue={ defaultValues.injuryLevel }
      label={ getLabel('injuryLevels', allOptions) }
      name="injuryLevel"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ...getOptions('injuryLevels', allOptions),
      ]}
    />

    <Field
      defaultValue={ defaultValues.injuryType }
      label={ getLabel('injuryTypes', allOptions) }
      name="injuryType"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ...getOptions('injuryTypes', allOptions),
      ]}
    />

    <Field
      defaultValue={ defaultValues.mobility }
      label={ getLabel('mobilityOptions', allOptions) }
      name="mobility"
      type="text"
      component={ RadioGroup }
      validate={ validators.required }
      disabled={ disabled }
      options={ getOptions('mobilityOptions', allOptions) }
    />

    {
      currentValues.mobility === 'walking' ?
        <Field
          key="walking"
          defaultValue={ defaultValues.mobilityOptions }
          disabled={ disabled }
          label={ getLabel('walkingOptions', allOptions) }
          help={ getHint('walkingOptions', allOptions) }
          name="mobilityOptions"
          component={ CheckboxGroup }
          options={ getOptions('walkingOptions', allOptions) }
        /> : null
    }

    {
      currentValues.mobility === 'wheelchair' ?
        <Field
          key="wheelchair"
          defaultValue={ defaultValues.mobilityOptions }
          disabled={ disabled }
          label={ getLabel('wheelchairOptions', allOptions) }
          name="mobilityOptions"
          component={ RadioGroup }
          validate={ validators.required }
          options={ getOptions('wheelchairOptions', allOptions) }
        /> : null
    }
  </>

export default withLocale(withOptions(Injured))
