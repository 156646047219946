import React, { Component } from 'react'
import { clamp, flowRight, get, includes, invert, keys, merge } from 'lodash'
import { Trans, t } from '@lingui/macro'
import { navigate } from 'gatsby'
import { Profile as Layout } from 'components/layout/Layout'
import { withAuth } from 'components/common/Auth'
import { withLocale } from 'components/common/Locale'
import Loading from 'components/common/Loading'
import ErrorMessage from 'components/common/ErrorMessage'
import Steps from 'components/common/Steps'
import { Main } from 'components/common/Form/Ui'
import { RegisterGeneralForm, RegisterSpecificForm, RegisterSuccess } from 'components/section/RegisterForm'
import routes from '~/routes'
import { register } from '~/service/user'
import { getLocationParam } from '~/utils/misc'
import { userTypes, errors } from '~/constants'

const totalSteps = 2

class RegisterPage extends Component {
  state = {
    hasError: false,
    errorCode: '',
    isSuccessful: false,
    currentStep: 0,
    data: {
      general: {},
      specific: {},
    },
  }

  static getDerivedStateFromProps(nextProps, currentState) {
    if (nextProps.isLoggedIn) {
      navigate(routes.profile())
    }

    return currentState
  }

  componentDidMount() {
    const locationUserType = getLocationParam(this.props.location, 'type')
    const defaultUserType = includes(userTypes, locationUserType) ? locationUserType : undefined
    this.setState({
      defaultUserType,
      data: {
        general: {
          type: defaultUserType,
        }
      },
    })
  }

  setStep = nextStep => {
    this.setState({
      currentStep: clamp(nextStep, 0, totalSteps - 1),
    }, this.scrollToTop)
  }

  handleFormChange = key => data => {
    this.setState({
      hasError: false,
      data: merge({}, this.state.data, {
        [key]: data,
      }),
    })
  }

  handleSubmit = async () => {
    const { data } = this.state
    const { i18n } = this.props
    this.setState({ isLoading: true })
    try {
      const user = await register({
        ...data.general,
        ...data.specific,
      })
      this.setState({ isSuccessful: true })
    } catch (e) {
      this.scrollToTop()
      this.setState({
        hasError: true,
        errorCode: e.status,
      })
    }
    this.setState({ isLoading: false })
  }

  scrollToTop = () => {
    window.scroll({ top: 0, behavior: 'smooth' })
  }

  render() {
    const { location } = this.props
    const { isSuccessful, hasError, errorCode, currentStep, data, defaultUserType } = this.state
    const { general, specific } = data

    return (
      <Layout location={ location }>
        {
          isSuccessful ?
            <RegisterSuccess
              isProfessional={ data.general.type === 'professional' }
            /> :
            <>
              <Steps
                total={ totalSteps }
                current={ currentStep }
              />
              {
                hasError ?
                  <Main>
                  {
                    errorCode === 409 ?
                      <ErrorMessage>
                        <Trans>Parece ser que ese usuario ya está registrado. Por favor, intenta accediendo a tu cuenta.</Trans>
                      </ErrorMessage>
                      :
                      <ErrorMessage>
                        <Trans>Ha ocurrido un error durante el registro. Por favor, intenta nuevamente más tarde.</Trans>
                      </ErrorMessage>
                  }
                  </Main> :
                  null
              }
              {
                currentStep === 0 ?
                  <RegisterGeneralForm
                    onChange={ this.handleFormChange('general') }
                    onSubmit={ () => this.setStep(1) }
                    defaultValues={ general }
                    defaultUserType={ defaultUserType }
                  />
                  :
                  <RegisterSpecificForm
                    type={ data.general.type }
                    onChange={ this.handleFormChange('specific') }
                    onSubmit={ this.handleSubmit }
                    defaultValues={ specific }
                  />
              }
            </>
        }
      </Layout>
    )
  }
}


export default withLocale(RegisterPage)
