import React from 'react'
import { noop } from 'lodash'
import { t } from '@lingui/macro'
import { Field } from 'components/common/Form'
import { withLocale } from 'components/common/Locale'
import { ImageUpload } from 'components/common/Input'
import { validators } from '~/utils/validation'

export default withLocale(({ i18n, defaultValues = {}, onChange = noop, disabled, isRequired, shouldAlwaysDisplayError }) =>
  <Field
    defaultValue={ defaultValues.picture }
    uploadMaxFilesize={ 1024 * 1024 }
    uploadLabel={ i18n._(t`Tu foto de perfil`) }
    uploadButton={ i18n._(t`Subir foto`) }
    name="picture"
    component={ ImageUpload }
    disabled={ disabled }
    onChange={ value => value && value !== defaultValues.picture ? onChange(value) : null }
    validate={ isRequired ? validators.required : undefined }
    shouldAlwaysDisplayError={ shouldAlwaysDisplayError }
  />
)