import React from 'react'
import { Trans, t } from '@lingui/macro'
import { Field } from 'components/common/Form'
import { withLocale } from 'components/common/Locale'
import { Select } from 'components/common/Input'
import { validators } from '~/utils/validation'
import { yearOptions } from '~/utils/misc'
import { OptionalFieldWrapper } from 'components/common/Form/Ui'
import { getOptions, getLabel } from './utils'
import withOptions from './withOptions'

const Relative = ({ i18n, onSubmit, defaultValues = {}, disabled }) =>
  <>
    <Field
      defaultValue={ defaultValues.firstName }
      label={ i18n._(t`Nombre`) }
      name="firstName"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <Field
      defaultValue={ defaultValues.lastName }
      label={ i18n._(t`Apellidos`) }
      name="lastName"
      type="text"
      validate={ validators.required }
      disabled={ disabled }
    />

    <OptionalFieldWrapper>
      <Field
        defaultValue={ defaultValues.phone }
        label={ i18n._(t`Teléfono de contacto`) }
        name="phone"
        type="text"
        disabled={ disabled }
      />
    </OptionalFieldWrapper>

    <OptionalFieldWrapper>
      <Field
        defaultValue={ defaultValues.relationship }
        label={ i18n._(t`Relación con el lesionado medular`) }
        name="relationship"
        component={ Select }
        disabled={ disabled }
        options={[
          ['', i18n._(t`Selecciona la opcion más apropiada`)],
          ['primergrado', i18n._(t`Primer grado`)],
          ['segundogrado', i18n._(t`Segundo grado`)],
          ['amigo', i18n._(t`Amigo/a`)],
          ['asistente', i18n._(t`Asistente o cuidador`)],
          ['otro', i18n._(t`Otro`)],
        ]}
      />
    </OptionalFieldWrapper>

    <Field
      defaultValue={ defaultValues.injuryLevel }
      label={ i18n._(t`Nivel de lesión`) }
      name="injuryLevel"
      component={ Select }
      validate={ validators.required }
      disabled={ disabled }
      options={[
        ['', i18n._(t`Selecciona la opcion más apropiada`)],
        ['paraplejia', i18n._(t`Paraplejía`)], /* @todo: Fuerza normal en brazos, manos y dedos de las manos. */
        ['tetraplejia', i18n._(t`Tetraplejía`)], /* @todo: Pérdida o alteración del movimiento o sensibilidad en las extremidades superiores e inferiores. */
      ]}
    />
  </>

export default withLocale(withOptions(Relative))
