import React from 'react'
import cx from 'classnames'
import { map, times } from 'lodash'
import style from './steps.module.scss'

export default ({ total, current }) => (
  <div className={ style.main }>
  {
    map(times(total), step =>
      <div key={ step } className={ cx(style.step, {
        [style.activeStep]: current === step,
      }) }>{ step + 1 }</div>
    )
  }
  </div>
)
