import React from 'react'
import { Link } from 'gatsby'
import { noop } from 'lodash'
import { Trans, t } from '@lingui/macro'
import { Field } from 'components/common/Form'
import { withLocale } from 'components/common/Locale'
import { Checkbox } from 'components/common/Input'
import { validators } from '~/utils/validation'
import routes from '~/routes'
import style from './fields.module.scss'

export default withLocale(({ i18n, locale, defaultValues = {}, currentValues, disabled }) =>
  <Field
    name="acceptTermsConditions"
    component={ Checkbox }
    defaultValue={ currentValues.acceptTermsConditions }
    type="checkbox"
    validate={ validators.required }
    selectionControlLabel={
      <>
        <Trans>Acepto los</Trans>{ ' ' }
        <a
          className={ style.link }
          target="_blank"
          href={
            routes.institutional({
              node_locale: locale,
              key: 'terms-and-conditions',
            })
          }><Trans>términos y condiciones</Trans></a>
      </>
    }
  />
)