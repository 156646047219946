import React from 'react'
import { Link } from 'gatsby'
import { Trans, t } from '@lingui/macro'
import Form from 'components/common/Form'
import routes from '~/routes'
import { withLocale } from 'components/common/Locale'
import { General } from 'components/section/ProfileFields'
import { Main, SubmitOrLogin, Title, Legal } from 'components/common/Form/Ui'

export default withLocale(({ i18n, locale, defaultUserType, onChange, onSubmit, defaultValues }) => (
  <Main>
    <Form
      onChange={ onChange }
      onSubmit={ onSubmit }
      render={ ({ isSubmitting, isValid, values: currentValues }) =>
        <>
          <Title>
          {
            defaultUserType === 'professional' ?
              <Trans>Formulario de registro <strong>para Profesionales</strong></Trans> :
              <Trans>Crea tu cuenta. <strong>Comparte. Aporta. Evoluciona.</strong></Trans>
          }
          </Title>
          <General
            currentValues={ currentValues }
            defaultValues={ defaultValues }
            defaultUserType={ defaultUserType }
            withInstitutionHelp
            canEditPassword
          />
          <SubmitOrLogin isDisabled={ isSubmitting || !isValid } />
          <Legal>
            <Trans>
              Al hacer clic en crear cuenta, aceptas los <Link to={ routes.institutional({
                node_locale: locale,
                key: 'terms-and-conditions',
              }) }>
                términos y condiciones de uso
              </Link> de la plataforma.
            </Trans>
          </Legal>
        </>
      }
    />
  </Main>
))
