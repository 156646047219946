import React from 'react'
import { Trans, t } from '@lingui/macro'
import Form from 'components/common/Form'
import Markdown from 'components/common/Markdown'
import { withLocale } from 'components/common/Locale'
import { Avatar, UserTypeSpecific, TermsAndConditions } from 'components/section/ProfileFields'
import { Main, SubmitOrLogin, Title } from 'components/common/Form/Ui'

const titlesByType = (i18n, type) => ({
  'injured': i18n._(t`**Saber más de ti** nos ayuda a darte un mejor servicio.`),
  'relative': i18n._(t`**Cuéntanos más** sobre esa persona con lesión medular para ayudarte mejor.`),
  'professional': i18n._(t`**Saber más de ti** nos ayuda a darte un mejor servicio.`),
})[type]

export default withLocale(({ i18n, onChange, onSubmit, type, ...props }) =>
  <Main>
    <Form
      onChange={ onChange }
      onSubmit={ onSubmit }
      render={ ({ isSubmitting, isValid, values: currentValues }) =>
        <>
          <Markdown
            component={ Title }
            content={ titlesByType(i18n, type) }
          />
          {
            type === 'professional' ?
              <Avatar
                {...props}
                isRequired
                shouldAlwaysDisplayError={ !!currentValues.firstName }
              /> :
              null
          }
          <UserTypeSpecific
            { ...props }
            type={ type }
            currentValues={ currentValues }
          />
          <TermsAndConditions
            { ...props }
            currentValues={ currentValues }
          />
          <SubmitOrLogin isDisabled={ isSubmitting || !isValid } />
        </>
      }
    />
  </Main>
)
