import React from 'react'
import { mapValues } from 'lodash'
import { graphql, StaticQuery } from 'gatsby'
import { filterLocalizedNodes } from '~/utils/content'
import { selectNodes } from '~/utils/misc'

export default Wrapped =>
  props =>
    <StaticQuery
      query={graphql`
        query ProfileFieldsOptionsQuery {
          allContentfulFieldOptions {
            edges {
              node {
                title
                label
                slug
                options {
                  value
                  label
                }
              }
            }
          }
        }`
      }
      render={ ({ allContentfulFieldOptions }) =>
        <Wrapped
          allOptions={ selectNodes(allContentfulFieldOptions.edges) }
          {...props}
        />
      }
  />