import React from 'react'
import Injured from './Injured'
import Relative from './Relative'
import Professional from './Professional'

const typeMap = {
  injured: Injured,
  relative: Relative,
  professional: Professional,
}

export default ({ type, ...props }) => {
  const Component = typeMap[type]
  return Component ? <Component {...props} /> : null
}
